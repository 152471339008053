import axios from 'axios';

const username = 'CONESSENCE_-a3f5h43g56dfgeh5_6dfc4h568f';
const password = 'W354yhj2ty_5h-456g24k56_A468zkj356dfv135h35'
const token = `${username}:${password}`;
console.log("laaaaa&a");

console.log("token", token);
const encodedToken = Buffer.from(token).toString('base64');

const xhr = axios.create({
    // baseURL: 'http://localhost:4000',
    baseURL: 'https://manager.conessence-pro.fr/api',
    headers: { 'Authorization': 'Basic ' + encodedToken }
});

// xhr.defaults.headers.common = {'Authorization': `bearer ${ localStorage.getItem('token')}`}

// Add a response interceptor
xhr.interceptors.response.use(
    response =>
    // Do something with response data
    response,
    error => {
        // Do something with response error
        return Promise.reject(error);
    }
);

export default xhr;