<template>
  <div>
    <div v-if="$route.name == 'Login'" class="bg"></div>

    <v-snackbar v-model="snackbar">
      Vos données ont été mise à jour
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Fermer</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarRooms">
      Les chambres de l'établissement ont été mise à jour dans le Manager
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbarRooms = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>

    <v-app id="app">
      <v-app-bar id="appBar" color="white" :height="60" app elevate-on-scroll>
        <img @click="$router.push('/')" width="200" height="60"
          src="@/assets/logo-hz.png" />
        <v-spacer></v-spacer>
      </v-app-bar>
      <notifications group="foo" />
      <v-navigation-drawer v-if="connected && $route.name != 'Login'" app>
        <v-list-item @click="$router.push('/')">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Conessence
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-badge dot class="ma-2" right color="success"></v-badge>
              Bonjour {{ user.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list v-if="connected">
          <v-list-item @click="$router.push('/')" class="px-2">
            <v-list-item-avatar>
              <v-img @click="$router.push('/')"
                src="@/assets/logo-vert.png">
              </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Accueil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-icon>
        
            <v-list-item-content>
              <v-list-item-title>Se déconnecter</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



        <v-divider></v-divider>


        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<style>
.opa {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>

<script>
import xhr from "./plugins/axios";
import { v4 as uuidv4 } from "uuid";
import "./App.scss";
const email = /^\S+@\S+\.\S+$/;
const long = /^.{3,}$/;
export default {
  name: "Login",
  data() {
    return {
      snackbarRooms: false,
      valid: true,
      manager: {
        id: uuidv4(),
        nom: "",
        prenom: "",
        email: "",
        password: "",
        property: "",
        admin: false,
      },
      loadingSync: false,
      dialog: false,
      connected: localStorage.getItem("user"),
      snackbar: false,
      annonce: "",
      select: "",
      dialogEditManager: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.name == "Edit") {
        this.snackbar = true;
      }
    },
  },
  created() {
    if (localStorage.getItem("rooms")) {
      this.rooms = JSON.parse(localStorage.getItem("rooms"));
    }


    this.user = localStorage.getItem("user");
    if (this.user) {
      this.user = JSON.parse(this.user);
    }


  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    goto(refName) {
      const element = document.getElementById(refName);
      // element.scrollIntoView();
      this.$vuetify.goTo(element);
      // goTo(refName);
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    add() {
      this.$router.push("/add");
    },
  },
};
</script>


