import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify/lib';
import router from './router';
import fr from 'vuetify/es5/locale/fr';
import 'vue-material-design-icons/styles.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import './registerServiceWorker'
import Notifications from 'vue-notification'
import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";


Vue.use(Vuetify);
Vue.use(Notifications)
Vue.use(VueMapbox, { mapboxgl: Mapbox });

let vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    lang: {
        locales: { fr },
        current: 'fr',
    },
    theme: {
        themes: {
            light: {
                primary: '#003E5C',
                secondary: '#91BE1E',
                accent: '#FFD205',
                error: '#EF7D00',
            },
        },
    },
});


Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    render: h => h(App),
}).$mount('#app');